<template>
  <div class="detail-page">
    <HeaderPageDetail :type-detail="type" :honor-detail="honorDetail"/>
    <h4 style="margin-top: 16px; color: white; white-space: pre-wrap">{{honorDetail?.title}}</h4>
    <p style="color: white; font-size: 16px; margin-top: 10px; text-align: justify; white-space: pre-wrap">{{honorDetail?.content}}</p>
    <div style="width: 100%; height: 1px; background: white; margin: 32px 0"></div>
    <div>
      <span style="color: #FCB813; font-weight: bold; font-size: 20px;">Bình luận</span>
      <div class="comment">
        <input class="comment-input" v-model="comment" placeholder="Nhập bình luận của bạn"/>
        <button :disabled="loading" class="comment-btn" @click="createComment">
          <i v-if="loading" class="el-icon-loading"></i>
          <i v-else class="el-icon-s-promotion"></i>
        </button>
      </div>
    </div>
    <div class="user-comment">
      <div class="user-comment__item" v-for="item in listComment" :key="item.id">
        <img v-if="item.avatar" class="user-comment__avatar" :src="item?.avatar" alt="avatar-user"/>
        <img v-else src="../../../../src/assets/img/user-default.png" class="user-comment__avatar" alt="avatar-default"/>
        <div class="user-comment__detail">
          <div class="user-comment__detail-header">
            <span style="color: #503694; font-size: 16px; font-weight: bold">{{ item?.name }}</span>
            <span style="color: gray; font-size: 12px">{{ " - Đã bình luận - " + item?.date   }}</span>
          </div>
          <p class="user-comment__detail-content" :class="item?.showMoreComment && !checkShowComment ? 'line-clamp-2' : 'h-100'" @click="checkShowComment = false">
            {{ item?.comment }}
          </p>
          <button
              style="display: flex; justify-content: end; border: none; background: transparent; text-decoration-line: underline; width: 100%"
              @click="checkShowComment = true" v-if="item?.showMoreComment && !checkShowComment">Xem thêm
          </button>
        </div>
      </div>
    </div>
    <div style="display: flex; justify-content: center; align-items: center; margin-top: 40px; height: 575px; width: 100%" v-if="type === 'okr'">
      <el-carousel indicator-position="none" style="height: 575px !important; width: 100%" arrow="never">
        <el-carousel-item v-for="(item, index) in postTheSameOkr" :key="index + 'okr'" style="height: 575px !important; width: 100%">
          <CardOKR type-card="carousel" :list-apartment="item"/>
        </el-carousel-item>
      </el-carousel>
    </div>
    <el-carousel indicator-position="outside" style="width: 100%; margin-top: 40px" v-if="type === 'staff'" arrow="never">
      <el-carousel-item v-for="item in postTheSame" :key="item.id">
        <CardStaffItem :staff="item"/>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import HeaderPageDetail from "@/views/components/honors/HeaderPageDetail.vue";
import {CREATE_COMMENT, GET_HONOR_BY_ID, GET_LIST_COMMENT} from "@/core/services/store/service.module";
import {CommentModel, HonorModel} from "@/views/pages/honors/Model";
import CardStaffItem from "@/views/components/honors/CardStaffItem.vue";
import CardOKR from "@/views/components/honors/CardOKR.vue";

export default {
  components: {CardOKR, CardStaffItem, HeaderPageDetail},
  data() {
    return {
      type: null,
      honorDetail: {},
      listComment: [],
      loading: false,
      comment: '',
      postTheSame:[],
      postTheSameOkr: [],
      checkShowComment: false,
    }
  },
  watch:{
    $route (){
      this.loadDataDetailHonor(this.$route.params.id)
    }
  },
  mounted() {
    this.type = this.$route.query.type;
    this.loadDataDetailHonor(this.$route.params.id)
  },
  methods: {
    loadDataDetailHonor(id) {
      this.getCommentByNewsletter(id)
      this.getHonorById(id)
    },
    getCommentByNewsletter(id) {
      this.$store.dispatch(GET_LIST_COMMENT, {id}).then(res => {
        this.listComment = res.data.map((item, index) => {
          return new CommentModel(
              index,
              item?.avatar,
              item?.user_name_comment,
              item?.time_comment,
              item?.comment,
          )
        });
      })
    },
    getHonorById(id) {
      this.$store.dispatch(GET_HONOR_BY_ID, id)
          .then(res => {
            try{
              const detail = res?.data?.newsletter
              const postNear = res?.data?.newsletterNear?.filter(item => item?.id !== this.$route.params.id)
              this.honorDetail = new HonorModel(
                  detail?.id,
                  detail?.category_name,
                  detail?.newsletter_desc,
                  detail?.newsletter_like,
                  detail?.newsletter_share,
                  detail?.content,
                  detail?.attach_files[0],
                  detail?.department,
                  detail?.user_like?.like,
                  detail.user_like?.share > 0,
                  detail?.newsletter_title,

              )
              this.postTheSame = postNear.length > 0 ? postNear?.map(item => {
                return new HonorModel(
                    item?.id,
                    item?.category_name,
                    item?.newsletter_desc,
                    item?.newsletter_like,
                    item?.newsletter_share,
                    item?.content,
                    item?.attach_files[0],
                    item?.department,
                    item?.user_like?.like,
                    item?.user_like?.share > 0,
                    item?.newsletter_title,

                )
              }) : []
              return this.postTheSame;
            }catch (e){
              console.log(e);
            }
          })
          .then(data => {
            this.getPostTheSameOkr(data);
          })
    },
    getPostTheSameOkr(arr) {
      for (let i = 0; i < arr.length; i += 4) {
        this.postTheSameOkr.push([...arr.slice(i, i + 4)]);
      }
    },
    createComment() {
      if(!this.comment) {
        this.$message.warning('Vui lòng nhập bình luận!')
        return false;
      }
      this.loading = true;
      this.$store.dispatch(CREATE_COMMENT, {
        newsletter_id: this.$route.params.id,
        comment: this.comment,
      }).then(res => {
        this.$message.success('Bình luận thành công')
        this.comment = '';
        this.loading = false
        this.loadDataDetailHonor(this.$route.params.id)
      }).catch(res => {
        console.log(res.error)
        this.loading = false
      })
    },
  }
}
</script>

<style lang="css" scoped>
.detail-page {
  padding: 50px 200px;
  background-image: linear-gradient(to bottom, #6454B5, #36296A);
  min-height: 100vh;
}

.comment {
  position: relative;
  margin-top: 20px;
}

.comment-input {
  border: none;
  outline: none;
  border-radius: 9999px;
  padding: 15px 80px 15px 20px;
  width: 100%;
}

.comment-btn {
  position: absolute;
  right: 32px;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  outline: none;
  background: transparent;
}

.comment-btn i {
  font-size: 25px;
  color: #503694;
}

.user-comment {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 25px;
}

.user-comment__item {
  display: flex;
  gap: 16px;
  align-items: flex-start;
}

.user-comment__avatar {
  border-radius: 9999px;
  object-position: center center;
  object-fit: cover;
  width: 50px;
  height: 50px;
}

.user-comment__detail {
  background: white;
  border-radius: 24px;
  height: auto;
  padding: 10px 15px;
  width: 100%;
}

.user-comment__detail-header {
  display: flex;
  gap: 8px;
  align-items: flex-end;
}
.user-comment__detail-content {
  font-size: 16px;
}

@media only screen and (max-width: 767px) {
  .detail-page {
    padding: 40px 36px;
    background-image: linear-gradient(to bottom, #6454B5, #36296A);
    min-height: 100vh;
  }
  .comment-btn {
    right: 15px;
  }
  .comment-input {
    padding: 10px 50px 10px 10px;
  }
  .user-comment__detail-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .detail-page {
    padding: 50px 80px;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1280px) {
  .detail-page {
    padding: 50px 100px;
  }
}
</style>